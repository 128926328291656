<template>
  <div class="transport-view">
    <div class="transport-view__wrapper">
      <HeroTop :backgroundImage="require('@/assets/images/aero.jpg')">
        <template slot="hero-top-breadcrumbs">
          <BreadCrumbs :params="{navigation, color: 'rgba(255, 255, 255, .5)'}"/>
        </template>
        <template slot="hero-top-title">
          <h1 class="hero-top__title grid-md-8 grid-lg-8">{{ $t('transport_view.transport') }}</h1>
        </template>
      </HeroTop>
      <Section limiter>
        <Column v-if="$city.slug === 'zheleznovodsk'"
                class="guide-view__tabs">
          <div class="guide-view__tab">
            <Row justify="between"
                 align="center"
                 class="guide-view__tabs-header"
                 v-on:click.native="toggle(0)">
              <span class="guide-view__tabs-header-title">{{ $t('transport_view.airport') }}</span>
              <Icon viewport="0 0 48 48"
                    :xlink="isOpen0 ? 'minus' : 'plus'"/>
            </Row>
            <transition name="fade">
              <div v-if="isOpen0"
                   class="guide-view__tabs-content">
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-title">{{ $t('transport_view.desc') }}</p>
                </div>
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-title">{{ $t('transport_view.contacts') }}</p>
                </div>
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-text">{{ $t('transport_view.info_service') }}</p>
                  <p class="guide-view__tabs-block-text">{{ $t('transport_view.tel') }}.:
                    8 800 333-03-21,</p>
                  <p class="guide-view__tabs-block-text">{{ $t('transport_view.tel') }}.:
                    +7 (87922) 27-305</p>
                  <p class="guide-view__tabs-block-text">{{ $t('transport_view.baggage_trace') }}
                    {{ $t('transport_view.tel') }}.: +7 (87922) 20-777
                    {{ $t('transport_view.add') }}. 40-75</p>
                  <p class="guide-view__tabs-block-text">{{ $t('transport_view.storage') }}
                    {{ $t('transport_view.tel') }}.: +7
                    (87922) 33-139</p>
                  <p class="guide-view__tabs-block-text">{{ $t('transport_view.vip_request') }}
                    {{ $t('transport_view.tel') }}.: +7
                    (87922) 20-475</p>
                </div>
                <div class="guide-view__tabs-block grid-md-5 grid-lg-5">
                  <LinkBox :params="{link: 'https://mvairport.ru/', blank: true}">
                    <template slot="link-box-title">{{ $t('transport_view.learn_more') }}</template>
                    <template slot="link-box-description">{{ $t('transport_view.airport_2') }}
                    </template>
                    <template slot="link-box-icon">
                      <Icon class="link-box__icon"
                            xlink="plane"
                            viewport="0 0 30 30"/>
                    </template>
                  </LinkBox>
                </div>
              </div>
            </transition>
          </div>
          <div class="guide-view__tab">
            <Row justify="between"
                 align="center"
                 class="guide-view__tabs-header"
                 v-on:click.native="toggle(1)">
              <span class="guide-view__tabs-header-title">{{ $t('transport_view.railroad') }}</span>
              <Icon viewport="0 0 48 48"
                    :xlink="isOpen1 ? 'minus' : 'plus'"/>
            </Row>
            <transition name="fade">
              <div v-if="isOpen1"
                   class="guide-view__tabs-content">
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-title">
                    {{ $t('transport_view.railroad_desc') }}</p>
                </div>
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-title">
                    {{ $t('transport_view.railroad_desc_2') }}</p>
                </div>
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-text">
                    {{ $t('transport_view.railroad_address') }}</p>
                </div>
                <div class="guide-view__tabs-block grid-md-5 grid-lg-5">
                  <LinkBox :params="{link: 'https://rasp.yandex.ru/station/9613059/', blank: true}">
                    <template slot="link-box-title">
                      {{ $t('transport_view.train_schedule') }}
                    </template>
                    <template slot="link-box-description">
                      {{ $t('transport_view.train_station') }}
                    </template>
                    <template slot="link-box-icon">
                      <Icon class="link-box__icon"
                            xlink="train"
                            viewport="0 0 30 30"/>
                    </template>
                  </LinkBox>
                </div>
              </div>
            </transition>
          </div>
        </Column>
        <Column v-if="$city.slug === 'stavropol'"
                class="guide-view__tabs">
          <div class="guide-view__tab">
            <Row justify="between"
                 align="center"
                 class="guide-view__tabs-header"
                 v-on:click.native="toggle(0)">
              <span class="guide-view__tabs-header-title">Аэропорт «Суворов»</span>
              <Icon viewport="0 0 48 48"
                    :xlink="isOpen0 ? 'minus' : 'plus'"/>
            </Row>
            <transition name="fade">
              <div v-if="isOpen0"
                   class="guide-view__tabs-content">
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-title">Добраться в Ставрополь из других регионов
                    можно, прилетев в Международный аэропорт Ставрополь имени А.В. Суворова.</p>
                </div>
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-title">Контакты</p>
                </div>
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-text">Адрес: 355008, Ставропольский край, г.
                    Ставрополь, Международный аэропорт Ставрополь.</p>
                  <p class="guide-view__tabs-block-text">тел.: +7 (8652) 24-55-57</p>
                </div>
                <div class="guide-view__tabs-block grid-md-5 grid-lg-5">
                  <LinkBox :params="{link: 'https://www.aeroport-stavropol.ru/', blank: true}">
                    <template slot="link-box-title">Узнать больше</template>
                    <template slot="link-box-icon">
                      <Icon class="link-box__icon"
                            xlink="plane"
                            viewport="0 0 30 30"/>
                    </template>
                  </LinkBox>
                </div>
              </div>
            </transition>
          </div>
          <div class="guide-view__tab">
            <Row justify="between"
                 align="center"
                 class="guide-view__tabs-header"
                 v-on:click.native="toggle(1)">
              <span class="guide-view__tabs-header-title">Железнодорожный вокзал</span>
              <Icon viewport="0 0 48 48"
                    :xlink="isOpen1 ? 'minus' : 'plus'"/>
            </Row>
            <transition name="fade">
              <div v-if="isOpen1"
                   class="guide-view__tabs-content">
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-title">Добраться в Ставрополь из других городов
                    можно поездом.</p>
                </div>
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-text">Адрес: 355008, Ставропольский кр., г.
                    Ставрополь, ул.Вокзальная 15</p>
                  <p class="guide-view__tabs-block-text">Единая справочная:</p>
                  <p class="guide-view__tabs-block-text">тел.: 8 (800) 775-00-00</p>
                  <p class="guide-view__tabs-block-text">Центр содействия мобильности:</p>
                  <p class="guide-view__tabs-block-text">тел.: 8 (800) 775-00-00 доб. 1</p>
                </div>
                <div class="guide-view__tabs-block grid-md-5 grid-lg-5">
                  <LinkBox :params="{link: 'http://stavropol.dzvr.ru/', blank: true}">
                    <template slot="link-box-title">Расписание поездов</template>
                    <template slot="link-box-icon">
                      <Icon class="link-box__icon"
                            xlink="train"
                            viewport="0 0 30 30"/>
                    </template>
                  </LinkBox>
                </div>
              </div>
            </transition>
          </div>
          <div class="guide-view__tab">
            <Row justify="between"
                 align="center"
                 class="guide-view__tabs-header"
                 v-on:click.native="toggle(2)">
              <span class="guide-view__tabs-header-title">Автовокзал</span>
              <Icon viewport="0 0 48 48"
                    :xlink="isOpen2 ? 'minus' : 'plus'"/>
            </Row>
            <transition name="fade">
              <div v-if="isOpen2"
                   class="guide-view__tabs-content">
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-title">Добраться в Ставрополь из других
                    городов и регионов можно автобусом, приехав на один из автовокзалов города.</p>
                </div>
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-text">Филиал «Ставропольский автовокзал»</p>
                  <p class="guide-view__tabs-block-text">Адрес: 355017, г. Ставрополь, ул.
                    Маршала Жукова, д. 27</p>
                  <p class="guide-view__tabs-block-text">Телефон: 8 (8652) 23-47-41</p>
                  <p class="guide-view__tabs-block-text">Режим работы: 4-30 – 23-00</p>
                </div>
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-text">Филиал «Ставропольская АС №1»</p>
                  <p class="guide-view__tabs-block-text">Адрес: 355042, г. Ставрополь, ул.
                    Доваторцев, д. 80А</p>
                  <p class="guide-view__tabs-block-text">Режим работы: 6-00 – 22-00</p>
                  <p class="guide-view__tabs-block-text">Телефон: 8 (8652) 57-38-98</p>
                </div>
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-text">Филиал «Ставропольская АС №2»</p>
                  <p class="guide-view__tabs-block-text">Адрес: 355000, г. Ставрополь, пр. Кулакова,
                    д. 18А</p>
                  <p class="guide-view__tabs-block-text">Режим работы: 6-45 - 19-45</p>
                  <p class="guide-view__tabs-block-text">Телефон: 8 (8652) 38-65-66</p>
                </div>
                <div class="guide-view__tabs-block grid-md-5 grid-lg-5">
                  <LinkBox :params="{link: 'https://avtovokzal26.ru/', blank: true}">
                    <template slot="link-box-title">Расписание автобусов</template>
                    <template slot="link-box-icon">
                      <Icon class="link-box__icon"
                            xlink="train"
                            viewport="0 0 30 30"/>
                    </template>
                  </LinkBox>
                </div>
              </div>
            </transition>
          </div>
        </Column>
        <Column v-if="$city.slug === 'minvody'"
                class="guide-view__tabs">
          <div class="guide-view__tab">
            <Row justify="between"
                 align="center"
                 class="guide-view__tabs-header"
                 v-on:click.native="toggle(0)">
              <span class="guide-view__tabs-header-title">Аэропорт</span>
              <Icon viewport="0 0 48 48"
                    :xlink="isOpen0 ? 'minus' : 'plus'"/>
            </Row>
            <transition name="fade">
              <div v-if="isOpen0"
                   class="guide-view__tabs-content">
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-title">Добраться в Железноводск из других
                    регионов можно, прилетев в Международный аэропорт Минеральные Воды имени М.Ю.
                    Лермонтова</p>
                </div>
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-title">Контакты</p>
                </div>
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-text">Справочная служба аэропорта
                    (Круглосуточно)</p>
                  <p class="guide-view__tabs-block-text">тел.: 8 800 333-03-21,</p>
                  <p class="guide-view__tabs-block-text">тел.: +7 (87922) 27-305</p>
                  <p class="guide-view__tabs-block-text">Служба розыска багажа (круглосуточно)
                    тел.: +7 (87922) 20-777 доб. 40-75</p>
                  <p class="guide-view__tabs-block-text">Камера хранения, бюро находок тел.: +7
                    (87922) 33-139</p>
                  <p class="guide-view__tabs-block-text">Заявки на обслуживание в VIP–зале тел.: +7
                    (87922) 20-475</p>
                </div>
                <div class="guide-view__tabs-block grid-md-5 grid-lg-5">
                  <LinkBox :params="{link: 'https://mvairport.ru/airport/address', blank: true}">
                    <template slot="link-box-title">Узнать больше</template>
                    <template slot="link-box-description">Международный аэропорт КМВ</template>
                    <template slot="link-box-icon">
                      <Icon class="link-box__icon"
                            xlink="plane"
                            viewport="0 0 30 30"/>
                    </template>
                  </LinkBox>
                </div>
              </div>
            </transition>
          </div>
          <div class="guide-view__tab">
            <Row justify="between"
                 align="center"
                 class="guide-view__tabs-header"
                 v-on:click.native="toggle(1)">
              <span class="guide-view__tabs-header-title">Железнодорожный вокзал</span>
              <Icon viewport="0 0 48 48"
                    :xlink="isOpen1 ? 'minus' : 'plus'"/>
            </Row>
            <transition name="fade">
              <div v-if="isOpen1"
                   class="guide-view__tabs-content">
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-title">Добраться в Железноводск из других городов
                    можно, приехав на Железнодорожный вокзал</p>
                </div>
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-title">Пункт продажи: Минеральные Воды - ж.д.
                    касса при вокзале – 887922-4-61-20</p>
                </div>
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-text">Адрес: 357200, Ставропольский край, г.
                    Минеральные Воды , Привокзальная площадь, 1</p>
                  <p class="guide-view__tabs-block-title">Единая справочная:</p>
                  <p class="guide-view__tabs-block-text">8 (800) 775-00-00</p>
                  <p class="guide-view__tabs-block-title">Центр содействия мобильности:</p>
                  <p class="guide-view__tabs-block-text">8 (800) 775-00-00 доб. 1</p>
                </div>
                <div class="guide-view__tabs-block grid-md-5 grid-lg-5">
                  <LinkBox :params="{link: 'http://mineralnyievodyi.dzvr.ru', blank: true}">
                    <template slot="link-box-title">Расписание поездов</template>
                    <template slot="link-box-icon">
                      <Icon class="link-box__icon"
                            xlink="train"
                            viewport="0 0 30 30"/>
                    </template>
                  </LinkBox>
                </div>
              </div>
            </transition>
          </div>
        </Column>
        <Column v-if="$city.slug === 'georgievsk'"
                class="guide-view__tabs">
          <div class="guide-view__tab">
            <Row justify="between"
                 align="center"
                 class="guide-view__tabs-header"
                 v-on:click.native="toggle(0)">
              <span class="guide-view__tabs-header-title">Аэропорт</span>
              <Icon viewport="0 0 48 48"
                    :xlink="isOpen0 ? 'minus' : 'plus'"/>
            </Row>
            <transition name="fade">
              <div v-if="isOpen0"
                   class="guide-view__tabs-content">
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-title">Добраться в Георгиевск из других регионов
                    России и не только можно,
                    прилетев в Международный аэропорт Минеральные Воды имени М.Ю. Лермонтова
                  </p>
                </div>
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-title">Контакты</p>
                </div>
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-text">
                    Справочная служба аэропорта (Круглосуточно)
                  </p>
                  <p class="guide-view__tabs-block-text">
                    тел.: 8 800 333-03-21,
                  </p>
                  <p class="guide-view__tabs-block-text">
                    тел.: +7 (87922) 27-305
                  </p>
                  <p class="guide-view__tabs-block-text">
                    Служба розыска багажа (круглосуточно) тел.: +7 (87922) 20-777 доб. 40-75
                  </p>
                  <p class="guide-view__tabs-block-text">
                    Камера хранения, бюро находок тел.: +7 (87922) 33-139
                  </p>
                  <p class="guide-view__tabs-block-text">
                    Заявки на обслуживание в VIP–зале тел.: +7 (87922) 20-475)
                  </p>
                </div>
                <div class="guide-view__tabs-block grid-md-5 grid-lg-5">
                  <LinkBox :params="{link: 'https://mvairport.ru ', blank: true}">
                    <template slot="link-box-title">Узнать больше</template>
                    <template slot="link-box-icon">
                      <Icon class="link-box__icon"
                            xlink="plane"
                            viewport="0 0 30 30"/>
                    </template>
                  </LinkBox>
                </div>
              </div>
            </transition>
          </div>
          <div class="guide-view__tab">
            <Row justify="between"
                 align="center"
                 class="guide-view__tabs-header"
                 v-on:click.native="toggle(1)">
              <span class="guide-view__tabs-header-title">Железнодорожный вокзал</span>
              <Icon viewport="0 0 48 48"
                    :xlink="isOpen1 ? 'minus' : 'plus'"/>
            </Row>
            <transition name="fade">
              <div v-if="isOpen1"
                   class="guide-view__tabs-content">
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-title">Добраться в Георгиевск
                    из других городов можно,
                    приехав на Железнодорожный вокзал</p>
                </div>
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-text">
                    Адрес: г. Георгиевск, Привокзальная площадь, 1
                  </p>
                  <p class="guide-view__tabs-block-text">
                    Пункт продажи билетов: касса ЖД вокзала: телефон: 8(87951) 2-21-25;
                  </p>
                  <p class="guide-view__tabs-block-text">
                    Единая справочная 8 (800) 775-00-00
                  </p>
                  <p class="guide-view__tabs-block-text">
                    Как проехать: маршрутные такси: №1, № 8
                  </p>
                  <p class="guide-view__tabs-block-text">
                    Часы работы: ежедневно- круглосуточно
                  </p>
                </div>
                <div class="guide-view__tabs-block grid-md-5 grid-lg-5">
                  <LinkBox :params="{link: 'https://rasp.yandex.ru/station/9613200/', blank: true}">
                    <template slot="link-box-title">Расписание поездов</template>
                    <template slot="link-box-icon">
                      <Icon class="link-box__icon"
                            xlink="train"
                            viewport="0 0 30 30"/>
                    </template>
                  </LinkBox>
                </div>
              </div>
            </transition>
          </div>
          <div class="guide-view__tab">
            <Row justify="between"
                 align="center"
                 class="guide-view__tabs-header"
                 v-on:click.native="toggle(2)">
              <span class="guide-view__tabs-header-title">Автостанция</span>
              <Icon viewport="0 0 48 48"
                    :xlink="isOpen2 ? 'minus' : 'plus'"/>
            </Row>
            <transition name="fade">
              <div v-if="isOpen2"
                   class="guide-view__tabs-content">
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-title">Автостанция расположена в центральной
                    части города Георгиевска. С автостанции организовано автобусное сообщение в
                    межмуниципальном и межрегиональном направлении, а также на курорты,
                    расположенные в Ставропольском крае.</p>
                </div>
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-title">Режим работы</p>
                </div>
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-text">
                    ООО «Автовокзал Георгиевский» 5:30-20:00 без перерыва и выходных.
                  </p>
                </div>
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-title">Контактная информация</p>
                </div>
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-text">
                    Адрес: г. Георгиевск, ул. Калинина, 20
                  </p>
                </div>
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-text">
                    Телефон: (87951) 2-45-82, 3-55-56
                  </p>
                </div>
              </div>
            </transition>
          </div>
        </Column>
        <Column v-if="$city.slug === 'pjatigorsk'"
                class="guide-view__tabs">
          <div class="guide-view__tab">
            <Row justify="between"
                 align="center"
                 class="guide-view__tabs-header"
                 v-on:click.native="toggle(0)">
              <span class="guide-view__tabs-header-title">Аэропорт</span>
              <Icon viewport="0 0 48 48"
                    :xlink="isOpen0 ? 'minus' : 'plus'"/>
            </Row>
            <transition name="fade">
              <div v-if="isOpen0"
                   class="guide-view__tabs-content">
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-title">
                    Добраться в Пятигорск из других регионов можно, приехав на Центральный
                    автовокзал (ул. Бунимовича, 34).
                  </p>
                  <p class="guide-view__tabs-block-title">
                    Напротив здания автовокзала на центральной магистрали города — улице Калинина
                    находится остановка общественного транспорта «Автовокзал».
                  </p>
                </div>
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-title">Контакты</p>
                </div>
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-text">
                    +7 (8793) 39-16-53
                  </p>
                </div>
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-text">
                    Город имеет разветвленную сеть общественного транспорта. Кроме того, маршрутные
                    такси курсируют от железнодорожного вокзала до соседних городов агломерации КМВ,
                    посёлков Терскол (турбазы Чегет и Азау, Приэльбрусье), а также связывают
                  </p>
                  <p class="guide-view__tabs-block-text">
                    Пятигорск со многими пригородными населёнными пунктами.
                  </p>
                  <p class="guide-view__tabs-block-text">
                    В городе работает около десяти фирм, предлагающих услуги такси.
                  </p>
                </div>
                <div class="guide-view__tabs-block grid-md-5 grid-lg-5">
                  <LinkBox
                      :params="{link: 'https://kmvavto.ru/stations/pyatigorsk.html', blank: true}">
                    <template slot="link-box-title">Узнать больше</template>
                    <template slot="link-box-icon">
                      <Icon class="link-box__icon"
                            xlink="plane"
                            viewport="0 0 30 30"/>
                    </template>
                  </LinkBox>
                </div>
              </div>
            </transition>
          </div>
          <div class="guide-view__tab">
            <Row justify="between"
                 align="center"
                 class="guide-view__tabs-header"
                 v-on:click.native="toggle(1)">
              <span class="guide-view__tabs-header-title">Железнодорожный вокзал</span>
              <Icon viewport="0 0 48 48"
                    :xlink="isOpen1 ? 'minus' : 'plus'"/>
            </Row>
            <transition name="fade">
              <div v-if="isOpen1"
                   class="guide-view__tabs-content">
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-title">Через город проходит линия
                    Северо-Кавказской железной дороги.</p>
                  <p class="guide-view__tabs-block-title">
                    В городе расположена пассажирская станция Пятигорск (с железнодорожным
                    вокзалом), станции Машук (пос. Энергетик), Лермонтовская, Скачки и станция
                    Ново-Пятигорск.
                  </p>
                  <p class="guide-view__tabs-block-title">
                    Электропоездом можно примерно за час добраться до Минеральных Вод или
                    Кисловодска.
                  </p>
                </div>
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-text">
                    Адрес: Ставропольский край, Пятигорск, Привокзальная площадь 1.
                  </p>
                </div>
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-text">
                    Пункт продажи: Пятигорск - кассы АО "ФПК" Станция: ПЯТИГОРСК.
                  </p>
                </div>
                <div class="guide-view__tabs-block grid-md-5 grid-lg-5">
                  <LinkBox
                      :params="{link: 'https://pass.rzd.ru/sellpoints/public/ru?layer_id=5477&STRUCTURE_ID=5243&id=20619', blank: true}">
                    <template slot="link-box-title">Расписание поездов</template>
                    <template slot="link-box-icon">
                      <Icon class="link-box__icon"
                            xlink="train"
                            viewport="0 0 30 30"/>
                    </template>
                  </LinkBox>
                </div>
              </div>
            </transition>
          </div>
          <div class="guide-view__tab">
            <Row justify="between"
                 align="center"
                 class="guide-view__tabs-header"
                 v-on:click.native="toggle(2)">
              <span class="guide-view__tabs-header-title">Автостанция</span>
              <Icon viewport="0 0 48 48"
                    :xlink="isOpen2 ? 'minus' : 'plus'"/>
            </Row>
            <transition name="fade">
              <div v-if="isOpen2"
                   class="guide-view__tabs-content">
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-title">Добраться в Пятигорск из других регионов
                    можно, прилетев в Международный аэропорт Минеральные Воды имени М.Ю. Лермонтова
                    – крупнейший аэропорт Ставропольского края и Северо-Кавказского федерального
                    округа, один из крупнейших на Юге России. Находится около 25 км от города
                    Пятигорска.</p>
                </div>
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-title">Справочная служба аэропорта
                    (Круглосуточно)</p>
                </div>
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-text">
                    ООО «Автовокзал Георгиевский» 5:30-20:00 без перерыва и выходных.
                  </p>
                </div>
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-title">Контактная информация</p>
                </div>
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-text">
                    тел.: 8 800 333-03-21;
                  </p>
                </div>
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-text">
                    тел.: +7 (87922) 27-305.
                  </p>
                </div>
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-title">Служба розыска багажа (круглосуточно)</p>
                </div>
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-text">
                    тел.: +7 (87922) 20-777 доб. 40-75.
                  </p>
                </div>
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-title">Камера хранения, бюро находок</p>
                </div>
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-text">
                    тел.: +7 (87922) 33-139.
                  </p>
                </div>
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-title">Заявки на обслуживание в VIP–зале</p>
                </div>
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-text">
                    тел.: +7 (87922) 20-475.
                  </p>
                </div>
                <div class="guide-view__tabs-block grid-md-5 grid-lg-5">
                  <LinkBox
                      :params="{link: 'https://kmvavto.ru/stations/pyatigorsk.html', blank: true}">
                    <template slot="link-box-title">Узнать больше</template>
                    <template slot="link-box-icon">
                      <Icon class="link-box__icon"
                            xlink="bus"
                            viewport="0 0 30 30"/>
                    </template>
                  </LinkBox>
                </div>
              </div>
            </transition>
          </div>
          <div class="guide-view__tab">
            <Row justify="between"
                 align="center"
                 class="guide-view__tabs-header"
                 v-on:click.native="toggle(3)">
              <span class="guide-view__tabs-header-title">Электротранспорт (трамвай)</span>
              <Icon viewport="0 0 48 48"
                    :xlink="isOpen3 ? 'minus' : 'plus'"/>
            </Row>
            <transition name="fade">
              <div v-if="isOpen3"
                   class="guide-view__tabs-content">
                <div class="guide-view__tabs-block grid-md-8 grid-lg-8">
                  <p class="guide-view__tabs-block-title">Пятигорский трамвай является одним из
                    старейших в России (его история началась в 1903 году). Маршруты трамвая
                    связывают практически все районы города с жизненно важными объектами –
                    вокзалами, рынками, магазинами, учреждениями.</p>
                </div>
              </div>
            </transition>
          </div>
        </Column>
      </Section>
      <ModifiedBillboard/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TransportView',
  data() {
    return {
      isOpen0: false,
      isOpen1: false,
      isOpen2: false,
      isOpen3: false,
    };
  },
  methods: {
    toggle(id) {
      const content = `isOpen${id}`;
      this[content] = !this[content];
    },
  },
  computed: {
    navigation() {
      return [{
        href: '/',
        title: this.$i18n.t('menu.main'),
      }, {
        href: '/transport',
        title: this.$i18n.t('menu.transport'),
      }];
    },
  },
};
</script>
